body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Seat Layout Container */
.seat-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  background-color: #f5faff;
  border-radius: 8px;
  margin-top: 16px;
}

/* General Seat Style */
.nseat, .bseat, .rseat {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

/* Normal Seat */
.nseat {
  background-color: #fff;
}

/* Blocked Seat */
.bseat {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Reserved Seat */
.rseat {
  background-color: #f00;
  cursor: not-allowed;
}

/* Selected Seat */
.nseat.selected {
  background-color: #1e89fe;
  color: #fff;
  border-color: #1e89fe;
}
